var exports = {};
var start = factory("start");
var end = factory("end");
exports = position;
position.start = start;
position.end = end;

function position(node) {
  return {
    start: start(node),
    end: end(node)
  };
}

function factory(type) {
  point.displayName = type;
  return point;

  function point(node) {
    var point = node && node.position && node.position[type] || {};
    return {
      line: point.line || null,
      column: point.column || null,
      offset: isNaN(point.offset) ? null : point.offset
    };
  }
}

export default exports;